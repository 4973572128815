import { Card, Row } from "react-bootstrap"
import { ReactComponent as EmptyTableImg } from "../../../assets/img/empty_state/images/empty_state-image-empty_table.svg"
import { ReactComponent as NoFoundImg } from "../../../assets/img/empty_state/images/empty_state-image-no_found.svg"
import { ReactComponent as EmptyLinkage } from "../../../assets/img/empty_state/images/empty_state-image-empty_table_linkages.svg"
import { ReactComponent as EmptyMoon } from "../../../assets/img/empty_state/images/empty_state-image-empty_moon.svg"
import { ReactComponent as EmptyPlans } from "../../../assets/img/empty_state/images/empty_state-image-empty_plans.svg"
import styles from "./EmptyTableMessage.module.scss"

interface EmptyTableMessageProps {
  title: string | React.ReactElement
  text: string | React.ReactElement
  type: "empty" | "not-found" | "empty-linkages" | "empty-moon" | "empty-plans"
  children?: React.ReactElement
  borderOfBox?: boolean
  containerClassName?: string
}

const EmptyTableMessage = ({
  title,
  text,
  type,
  children,
  borderOfBox = true,
  containerClassName,
}: EmptyTableMessageProps) => {
  const Container = borderOfBox ? Card : "div"

  return (
    <Container className={`${styles.container} ${containerClassName}`}>
      <Row className="justify-content-center">
        {type === "empty" ? (
          <EmptyTableImg />
        ) : type === "empty-linkages" ? (
          <EmptyLinkage />
        ) : type === "empty-moon" ? (
          <EmptyMoon />
        ) : type === "empty-plans" ? (
          <EmptyPlans />
        ) : (
          <NoFoundImg />
        )}
      </Row>
      <Row className="justify-content-center">
        {typeof title === "string" ? (
          <Card.Text className={styles.cardTitle}>{title}</Card.Text>
        ) : (
          <div className={styles.cardTitle}>{title}</div>
        )}
      </Row>
      <Row className="justify-content-center">
        <Card.Text className={styles.cardText}>{text}</Card.Text>
      </Row>
      {children}
    </Container>
  )
}

export default EmptyTableMessage
